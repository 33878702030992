header {
    height: 100vh;
    padding-top:7rem;
    overflow:hidden;
}

.header__container {
    text-align:center;
    height:62%;
    position:relative; 
}

/* call to action */

.cta {
    margin-top: 8rem;
    display:flex;
    gap: 1.2rem;
    justify-content:center;
}

.cv__shake:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}



/* Socials */

.header__socials {
    display:flex;
    flex-direction:column;
    align-items:center;
    gap: 0.8rem;
    position:absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials > a {
    color: var(--color-bg);
}

.header__socials::after {
    content: '';
    width:1px;
    height: 2rem;
    background: var(--color-bg);
}

/* Me img */

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 25rem;
    position:absolute;
    left: 50%;
    transform: translate(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    z-index: -1;
}

/* Scroll down */

.scroll__down {
    position:absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    color: var(--color-bg);
}


/* Media queries (tablets) */

@media screen and (max-width: 1024px) {
    header {
        height: 68vh; 
    }
    .cta {
        margin-top: 12rem;
        margin-bottom: 12rem;
    }
}


/* Media queries (phones) */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display:none;
    }
}
